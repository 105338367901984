new Typed('#technologies', {
	strings: [
		'how <u>3D Graphics</u> work?',
		'how <u>Asymmetric Encryption</u> works?',
		'how <u>Autonomous Vehicles</u> work?',
		'how <u>Blockchains</u> work?',
		'how <u>Bluetooth</u> works?',
		'how <u>BIOSs and Boot Loaders</u> work?',
		'how <u>Cloud Computing</u> works?',
		'how <u>CPUs, GPUs, and ICs</u> work?',
		'how <u>Cybersecurity</u> works?',
		'how <u>Digital Electronics</u> work?',
		'how <u>Distributed Computing</u> works?',
		'how <u>Distributed Databases</u> work?',
		'how <u>DNS and Internet Routing</u> works?',
		'how <u>Email Servers</u> work?',
		'how <u>Ethernet and Firewalls</u> work?',
		'how <u>Game and Physics Engines</u> work?',
		'how <u>Genetic Algorithms</u> work?',
		'how <u>Graph Databases</u> work?',
		'how <u>AI and Machine Learning</u> works?',
		'how <u>Natural Language Processing</u> works?',
		'how <u>Neural Networks</u> work?',
		'how <u>Operating Systems</u> work?',
		'how <u>Parallel Processing</u> works?',
		'how <u>Peer-to-Peer Networks</u> work?',
		'how <u>Quantum Computers</u> work?',
		'how <u>Random Access Memory</u> works?',
		'how <u>Robots and Machine Vision</u> works?',
		'how <u>Satellites</u> work?',
		'how <u>Solid-State Drives</u> work?',
		'how <u>Web Servers and Websites</u> work?',
		'how <u>Wireless Networking</u> works?',
		'to program in <u>Assembly, Rust, Go</u>?',
		'to program in <u>C, C++, C#</u>?',
		'to program in <u>Java, Kotlin, Groovy</u>?',
		'to program in <u>JavaScript, TypeScript, Sass</u>?',
		'to program in <u>Python, Ruby, Swift</u>?',
		'to program in <u>SQL, GraphQL, Cypher</u>?',
		'<u>3D, VR, and AR Game</u> development?',
		'<u>iOS and Android App</u> development?',
		'<u>Web</u> development?'
	],
	shuffle: true,
	loop: true,
	loopCount: 5,
	typeSpeed: 70,
	backDelay: 3700
})
